a { 
  color: #748E54; 
  padding-bottom:.1rem;
  margin-bottom: .05rem; 
}

a:hover {

  color: #748E54; 
 text-decoration: none;
}


.name:hover{
    color: #748E54;
}


.underlinecolor:hover{
  color: #748E54;
 
  
}

.underlinecolor{
  color: #748E54;
 text-decoration: none;
}

.underlinecolor:hover{
  color: #748E54;
 text-decoration: underline;
}

.intro{
      margin-top: 25%;
      margin-bottom: 8%;
      margin-right:25%;
      font-size: 3.3vw;
      color: #232020;
}



p {
  font-size: 1vw;
  letter-spacing: 1.2px;
  font-weight: 400;
  text-align: left;
  line-height: 2;
  color: #232020;
}

li{
  font-size: 1vw;
  letter-spacing: 1.2px;
  font-weight: 400;
  text-align: left;
  line-height: 2;
  color: #232020;
  
}

ul{
  list-style-type: circle;
}

body {
  font-family: "Open Sans", sans-serif; 
  background-image: linear-gradient(to bottom,#fffffd ,#fffffb );
  color: #232020;
}

h1, h2, h3 {
  font-family: 'Roboto', sans-serif;
  color: #232020;
}

h1{
  font-size: 4vw;
  letter-spacing: 8px;
  font-weight: 500;
}


h2 {
  font-size: 1.5vw;
  letter-spacing: 1.2px;
  font-weight: 400;
  text-align: left;
  line-height: 2;
}

h3 {
  font-size: 1.8vw;
  letter-spacing: 2px;
  font-weight: 700;
  
}
h4 {
  font-size: 2vw;
  letter-spacing: 2px;
  margin:0rem;
  padding:16px;

  
} 

h5 {
  font-size: 2vw;
  letter-spacing: 3px;
  font-weight: 600;
  color: #232020;
  text-align: left;
}

h6 {
  font-size: 2vw;
  letter-spacing: 3px;
  font-weight: 400;
  color: #232020;
}

  .card-body{
    padding: .2vw;
 }
     

 

.nav-link{
  text-align:right;
}
.nav-item h5{
  text-align:right;
}



.navbar{
  padding-top: 2px; 
    
  margin-top: 0rem;
  box-shadow: 0px 0px 25px -15px rgba(140, 140, 110, .5);
  border-radius:0px 0px 35px 35px ;

  
}

.navbar a{
  color: #686554;

padding: .5em;
}

.navbar a.active {
  text-decoration: underline solid 3px;
  text-decoration-color: #748E54;
color: #232020;
 padding: .5em;
  border-radius: 1em;
}
.navbar a.hover{

  padding: .5em;

}
.btn{
  margin: auto;
}

.btn-outline-info{
  background-color: #c3f7f7;
  color:black;
  padding: 0rem;
  padding-left:.5rem;
  padding-right:.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
 border-color: #c3f7f7;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 35px;
}

.btn-outline-info:hover{
  background-color: #8ed4cf;
   border-color: #c3f7f7;
  color:black;
  padding-left:2rem;
  padding-right:2rem;

  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 35px;
}

.btn-outline-info:active{
  background-color: #8ed4cf;
   border-color: #c3f7f7;
  color:black;
  padding-left:2rem;
  padding-right:2rem;
 border-color: #c3f7f7;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 35px;
}
.lcard {
  margin-top: 5em;
  margin-bottom: 5em;
  padding-top: 3em;
  padding-bottom: 3em ;
  text-align: center;
  
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
  transition: 0.3s;

}



.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
}
.icard:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);

}

.text-center {
  margin: auto;

  text-align: center;
 
  
 
}

.outlineIn{
  border: solid 1px #FFB347;
}

.imgcenter{
  margin: auto;
  text-align: center;
   align-self: center;
  max-height: 30%;
  max-width:100%;
  display:block;
}

.marginl{
  margin: auto;
}

.banner{
  margin: auto;
  text-align: center;
  align-self: center;
  max-height: 30%;
  max-width:85%;
  display:block;
}

.imgstyle{
  max-width: 80%;
  border-radius: 25px;
}

img{
  border-radius: 25px;
}


.trailer{
  width: 90%;
  height: 90%;
  margin:auto;

}



@media (min-width: 100px) and (max-width: 992px){

  p {
    font-size: 2vw;
    letter-spacing: 1.2px;
    font-weight: 400;
    text-align: left;
    line-height: 2;
  }



  li{
    font-size: 1.8vw;
    letter-spacing: 1.2px;
    font-weight: 400;
    text-align: left;
    line-height: 2;
  }

  h2 {
    font-size: 3.3vw;
  }

  h1 {
    font-size: 3.5vw;
  }
  
  h4 {
    font-size: 4vw;
    font-weight: 500;
  }

  h5{
    font-size: 2.5vw;
    text-align: center;
  }

  h6{
    font-size: 4vw;
    font-weight:700;
    color: #0C171C;
  }

  .banner{
  height: auto;
 width:100%;
 padding-top: 0px;
  }

  .navbar{
    background-color: white;
    color: #0C171C;
    padding-bottom: .3rem; 
    padding-top: .5rem; 
    top: 0;
    width: 100%;
    position: sticky;
    padding-left: 2.5rem;
    padding-right:2rem;
    margin-left:auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
  }

 .navbar-toggler {
    padding: 0.25rem 0.25rem;
    font-size: 1.rem;
    line-height: 1;
    color:#0C171C;
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 0.25rem;
  }

 .navbar-toggle {
  
    background-color:#0C171C;

  }

    .navbar-collapse{
  text-align: right;
  align-items: right;
  
    float:right;
  }

  .carouselObj {
    margin: auto;
    align-items: center;
    padding: 5px;
   
    border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(153,143,115,1);
  }   

  .aboutme{
    text-align: center;
    margin-top: 3em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7rem;
    background: white;
    
    padding: 2rem;
    border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(153,143,115,1);
  }
  .lcard {
    margin-top: 5em;
    margin-bottom: 5em;
    

    padding-top: 3em;
    padding-bottom: 3em ;
    text-align: center;
    border-radius: 1em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 0.3s;

  }

     .icard {
 margin-top: .5em;
    margin-bottom: .5em;
    padding: 1.5em;
    text-align: center;
    border-radius: 1em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: #fcfffc;
  }


  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .breadAlign{
    margin-left:0rem ;
    margin-top: 0;
    padding: .1rem;
    background-color: white;
     text-align: center;
     border-radius: .5em;
     position: sticky;

    
  }



}


@media (min-width: 768px) and (max-width: 992px){

  h2 {
    font-size: 2vw;
  }

  h1 {
    font-size: 3vw;
  }
  
  h4 {
    font-size: 1.5vw;
    font-weight: 500;
  }

  h5{
    font-size: 2vw;
  }

  h6{
    font-size: 2.6vw;
    font-weight: 900;
    
  }

  .aboutme{

    padding: 3rem;
  
  }
.carouselObj {
    margin-right: 3rem;
    align-items: center;
    padding: 5px;
    margin-top: 2em;
    margin-bottom: auto;
 
    border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(120, 156, 156,1);
  }   

  .aboutme{
    text-align: center;
    margin-top: 3em;
    margin-left: 4rem;
    margin-bottom: 7rem;
    background: white;
   
    padding: 2rem;
    border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(120, 156, 156,1);
  }

  .nav-item{
margin-left: auto;
    display: flex;
    pading:0;
  }
       .icard {
 margin-top: .5em;
    margin-bottom: .5em;
    padding:2rem;
    text-align: center;
    border-radius: 1em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: #fcfffc;
  }

}

@media (min-width: 993px) and (max-width: 1199px){

  h2 {
    font-size: 2vw;
  }
  a{
    text-align: center;
  }

  p, ul, li {
    font-size: 1.2vw;
  }

  h1 {
    font-size: 2vw;
  }
  
  h4 {
    font-size: 1.4vw;
    font-weight: 500;
  }

  h5{
    font-size: 1.7vw;
    text-align: center;
  }

   h3{
    font-size: 1.4vw;
    font-kerning: 1;
    text-align: center;
  }


  h6{
    font-size: 2vw;
    font-weight: 700;
    
  }

 

 .navbar {

     font-weight: 500;
    position:-webkit-sticky; 
    top:0;
   background-color: white;
    color: #0C171C;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    z-index: 1;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    -webkit-box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.2);
    

  } 
  
  .nav-item{
      margin-left: auto;
    display: flex;

  }
  .navbar-collapse{
 
  }

  .carouselObj {
    margin: auto;
    margin-right: 4em;
    margin-top: 4em;
    margin-bottom: 4em;
    align-items: center;
    padding: 5px;
   
        border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(120, 156, 156,1);
  }  

  .aboutme{
    text-align: block;
    margin-top: auto;
    margin-left: auto;
    margin-bottom: auto;
    padding: 4rem;
    background: white;
    align-content: center;
    border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(120, 156, 156,.5);
  }



  .lcard {
    
    margin-bottom: 100px;
       
    background: rgb(175, 136, 136);
    border: solid 1px #fff4d2;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 0.3s;

  }

      .icard {
 margin-top: 2em;
    margin-bottom: 1em;
    padding: 2.5em;
    
    padding-bottom: 1em;
    text-align: center;
    border-radius: 1em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 0.3s;
    background-color: #fcfffc;
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .breadAlign{
    margin-left: 0rem;
      padding-bottom: 3px;
    padding-top: 3px;
    background-color: white;
    position: sticky;
  }
}

@media (min-width: 1200px) and (max-width: 1399px){

 p {
    font-size: 1.2vw;
  }
ul, li{
    font-size: 1.3vw;
  }

      h2 {
    font-size: 1.5vw;
  }
      h3 {
    font-size: 1.6vw;
  }
  h1 {
    font-size: 2vw;
  }
  
  h4 {
    font-size: 1.4vw;
    font-weight: 500;
  }

  h5{
    font-size: 2vw;
    text-align: center;
    padding-bottom: .3em;
  }

  h6{
    font-size: 1.8vw;
    font-weight: 900;
    
  }

.navbar{
    background-color: white;
    color: #0C171C;
    padding-bottom: .8rem; 
    padding-top: .8rem; 
    top: 0;
    width: 100%;
    position: sticky;
    padding-left: 2.5rem;
    padding-right:2rem;
    margin-left:auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
  }


    .navbar-collapse{
  text-align: right;
  align-items: right;
  
    float:right;
  }

.nav-item{
      margin-left: auto;
    display: flex;

  }

  .carouselObj {
    margin: auto;
    margin-top: 4em;
    margin-bottom: 4em;
    align-items: center;
    padding: 5px;
            border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(120, 156, 156,.5);
  }  
    .carouselObj::before {
  position: absolute;
  top: -10%;
  left: -10%;
  width: 100%;
  height: 100%;
  border: 10px solid ##748E54;
  border-radius: 25px;
  content: '';
}

  .aboutme{
    text-align: block;
    margin-top: auto;
    margin-left: auto;
    margin-bottom: auto;

    padding: 3rem;
    background: rgba(255, 255, 254, 0.59);
   
        border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(120, 156, 156,.5);
  }


  
  .lcard {
    margin-top: 2rem;
    margin-bottom: 2rem;

    padding-top: 2rem;
    padding-bottom: 2rem ;
    background-color: white;
 
        border-radius: 1em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 0.3s;

  }

       .icard {
 margin-top: 2em;
    margin-bottom: 2em;
    padding: 3em;
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: center;
    border-radius: 1em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 0.3s;
    background-color: #fcfffc;
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .breadAlign{
    margin-left:0rem;
    background-color: white;
     text-align: center;
    position:sticky-top;
      padding-bottom: 3px;
    padding-top: 3px;

  }
     .container{
    
    max-width: 95%;
  }

@media(max-height: 800px){
      .container{
    max-width: 85%;
  }
}


}

@media (min-width: 1400px)and (max-width: 1918px){

   h2 {
    font-size: 1.5vw;
  }
      h3 {
    font-size: 1.6vw;
  }
  h1 {
    font-size: 3vw;
  }
  
  h4 {
    font-size: 1.4vw;
    font-weight: 500;
  }

  h5{
    font-size: 1.7vw;
  }

  h6{
    font-size: 2.5vw;
    font-weight: 900;
    
  }


  .navbar {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0;
    background-color: white;
    color: #0C171C;
    font-weight: 500;
    position:-webkit-sticky; 
    position:sticky;
    top:0rem;
    z-index: 1;
  } 

 .navbar-collapse{
    text-align: right;
    margin-left: 36rem;
    float:right;
  }

.nav-item{
      margin-left: auto;
    display: flex;

  }

  .carouselObj {
    margin: auto;
    margin-top: 12%;
    display: flex;
    position: relative;
    align-items: center;
    padding: 5px;
    border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(120, 156, 156,.5);

  }  

.carouselObj::before {
  position: absolute;
  top: -10%;
  left: -10%;
  width: 100%;
  height: 100%;
  border: 10px solid #748e54;
  border-radius: 25px;
  content: '';
}

  .aboutme{
    text-align: center;
    margin: auto;
    margin-right: 4rem;
    margin-top: 5%;
    padding: 4rem;
    background: rgba(255, 255, 254, 0.8);
        border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(120, 156, 156,.5);
  }

    .navbar-collapse{
 
    margin-left: 16rem;
    float:right;
  }

  .align{
    margin-top: -800px;
    text-align: center;
    padding: 30px;
    margin: auto;
    margin-left: 250px;
  }   
  
  .lcard {
    margin-top: 4rem;
    margin-bottom: 3rem;
    border-radius: 1em;
    padding-top: 2em;
    padding-bottom: 2em ;
    background-color: white;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 0.3s;

  }



  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .breadAlign{
    margin-left: 0rem;
    background-color:white;
     text-align: center;
     position: sticky;
     padding-bottom:  .1rem;
     padding-top:  .1rem;
  }



  h1{
    font-size: 2vw;
  }

  h2{
    font-size: 1vw;
  }

  h3 {
    font-size: 1.6vw;
       padding-bottom: .3em;
  }

  h4{
    font-size: 1.5vw;
  }

  h5{
    font-size: 2.0vw;
    text-align: center;
    padding-bottom: .3em;
    padding-top: .3em;
  }

   h6{
    font-weight: 700;
    font-size:1.9vw;
  
  }

   .icard {
     margin-top: 1em;
    margin-bottom: 1em;
   padding: 2rem;
    padding-left: 5rem;
     padding-right: 5rem;
    background-color: white;
       border-radius: 1em;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05);
    transition: 0.3s;
    text-align: center;

  }

    .container{
    
    max-width: 95%;
  }
.banner{
    margin: auto;
    text-align: center;
    align-self: center;
    max-height: 20%;
    max-width:76%;
    display:block;
  }


}

@media (min-width: 1919px){


  p{
     font-size: .7vw;
  }
  li{
    font-size: .8vw;
    padding: .2em;
  }
  u{
     padding: .3em;
  }

  h1{
    font-size: 1.1vw;
  }

  h2 {
    font-size: .7vw;
  }

  h3 {
    font-size: 1.4vw;
     text-align: center;
     padding-bottom: .3em;
  }

  h4{
    font-size: 1.4vw;
  }

  h5{
    font-size: 2.0vw;
    text-align: center;
    padding-bottom: .2em;

  }

  h6{
    font-weight: 700;
    font-size:1.4vw;
    padding: 0em;
    margin: 0em;
  
  }
  .name{
    text-align: left;
    align-items: left;
  }

  .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0;
    margin-left: 0rem;
    margin-right: 0rem;
    background-color: white;
    color: #0C171C;
    font-weight: 500;
    position:-webkit-sticky; 
    position:sticky;
    top:0rem;
    z-index: 1;
  } 
 .carouselObj::before {
  position: relative;
  top: -10%;
  left: -10%;
  width: 100%;
  height: 100%;
  border: 10px solid ##748E54;
  border-radius: 25px;
  content: '';
}

  .carouselObj {
    margin:auto;
    margin-top: 9%;
    padding: .1rem;
    
               border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(120, 156, 156,.5);
  }   

 

  .aboutme{
    text-align: block;
    margin-top: 8%;
    margin-left: 6%;
    margin-bottom: 4%;
    padding: 4rem;
    background: white;
    
          border-radius: 25px;
    box-shadow: 9px 9px 31px -17px rgba(120, 156, 156,.5);
  }

    .navbar-collapse{
 
    margin-left: auto;
    float:right;
      text-anchor: right;
      text-anchor: 100%;
  }
      .collapse{
 
    
    float:right;
    text-anchor: right;
  }

  .nav-item{
      margin-left: auto;
    display: flex;

  }

  .name{
    overflow:hidden;
      float:left;
      text-align: left;
  }
  
  .lcard {
    margin-top: 4rem;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
    padding-bottom: 2em ;
    background-color: white;
       border-radius: 1em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 0.3s;

  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .breadAlign{
    margin: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    display: flex;
    float:center;
    text-align: center;
  align-self: center;
    background-color: white;
     width: 100%;
  }

  .breadcrumb-item{
    padding:0;
  }

  .container{
    
    max-width: 88%;
  }
.banner{
  margin: auto;
  text-align: center;
  align-self: center;
  max-height: 20%;
  max-width:76%;
  display:block;
}

.imgcenter{
  margin: auto;
  text-align: center;
   align-self: center;
  max-height: 30%;
  max-width:90%;
  
}

.imgstyle{
  max-width:80%;
  height: auto;
}

 .icard {
  text-align: center;
     margin-top: 2em;
    margin-bottom: 2em;
   padding: 2rem;
    padding-left: 6rem;
     padding-right: 6rem;
    background-color: white;
       border-radius: 1em;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05);
    transition: 0.3s;


  .card-body {
    padding: .2vw;
  }

  @media (max-height: 900px){
  
    p{
       font-size: .7vw;
    }
    li{
      font-size: .8vw;
      padding: .2em;
    }
    u{
       padding: .3em;
    }

    h1{
      font-size: 1.0vw;
    }

    h2 {
      font-size: .7vw;
    }

    h3 {
      font-size: .8vw;
       text-align: center;
       padding-bottom: .3em;
    }

    h4{
      font-size: 1.3vw;
    }

    h5{
      font-size: .9vw;
      text-align: center;
      padding-bottom: .2em;

    }

    h6{
      font-weight: 700;
      font-size:1.7vw;
      padding: 0em;
      margin: 0em;
    
    }
  .container{
      
      max-width: 70%;
    }
  .banner{
    margin: auto;
    text-align: center;
    align-self: center;
    max-height: 20%;
    max-width:76%;
    display:block;
  }
}


  }
}


.lcard {
  margin-top: 2em;
  margin-bottom: 2em;
 

  padding: 1rem;
  
  background: white;

}



footer h3{
  color: #FFB347;
}

.site-footer {
  padding:0;
  background-color: rgba(255, 255, 254, 0.81);
  margin-top: 4%;
  border-radius: 25px 25px 0px 0px;
  box-shadow: 0px 0px 31px -17px rgba(140, 140, 110, .5);
}

  .card{
    border-color: white;
  }

  .imgstyle{
       border-radius: 1.5vw;
       padding-top: .2vw;
  }


  .info{
    margin:auto;
    padding:auto;
  }

  

 p:hover {
 font-weight: 600;
   font-size-adjust: 90%;
  padding:0rem;
}
 li:hover {
 font-weight: 600;
}

.bg{
      background-color: white;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(13, 26, 56, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}